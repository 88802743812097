import React, { Fragment } from "react"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Context as ResponsiveContext } from "react-responsive"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import CareerEntry from "../components/CareerEntry"
import CareersMobileBanner from "../components/CareersMobileBanner"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import styles from "./careers.module.scss"

const PreviousLink = ({ pageNumber }) => {
  let previousLink = null

  if (!pageNumber) {
    return null
  } else if (1 === pageNumber) {
    previousLink = "/careers/"
  } else if (1 < pageNumber) {
    previousLink = `/careers/page/${pageNumber - 1}`
  }

  return (
    <Button
      type="primary"
      onClick={() => navigate(previousLink)}
      className={styles.paginate}
    >
      {`« Previous`}
    </Button>
  )
}

const NextLink = ({ hasNextPage, pageNumber }) => {
  if (hasNextPage) {
    return (
      <Button
        type="primary"
        onClick={() => navigate(`/careers/page/${pageNumber + 1}`)}
        className={styles.paginate}
      >
        {`Next »`}
      </Button>
    )
  } else {
    return null
  }
}

const CareersTemplate = props => {
  const {
    data,
    pageContext: { hasNextPage, pageNumber },
  } = props

  const careerItems = data.wpcontent.careers.nodes
  const careersPageNumber = pageNumber ? ` Page ${pageNumber}` : ``

  return (
    <Layout>
      <SEO title={`Careers ${careersPageNumber}`} />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.bannerBackgroundImage.publicURL});
          }
        `}
      >
        <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
          <CareersMobileBanner
            title="Careers"
            subTitle="Come and join our dynamic team!"
          />
        </ResponsiveContext.Provider>
        <ResponsiveContext.Provider value={{ minWidth: 576 }}>
          <Container className={styles.bannerContainer}>
            <Row className="mt-5">
              <Col className={styles.header}>
                <h1>Careers</h1>
                <p>Come and join our dynamic team!</p>
              </Col>
              <Col>
                <Img
                  fluid={data.banner.childImageSharp.fluid}
                  alt="AccountablePH Careers"
                />
              </Col>
            </Row>
          </Container>
        </ResponsiveContext.Provider>
      </Container>
      <Container fluid className={styles.cardContainer}>
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={10} lg={10} xl={8}>
            {!careerItems.length ? (
              <div className={styles.emptyCareerContainer}>
                <p>
                  We're not Hiring yet!
                  <br />
                  <span>
                    Just come back when we have an open positions in the future
                    :)
                  </span>
                </p>
              </div>
            ) : (
              data &&
              data.wpcontent &&
              careerItems.map(career => (
                <Fragment key={career.id}>
                  <CareerEntry career={career} />
                </Fragment>
              ))
            )}
          </Col>
        </Row>
      </Container>
      <Container className="pb-5">
        <div className={styles.paginatorContainer}>
          <div className={styles.prevLink}>
            <PreviousLink pageNumber={pageNumber} />
          </div>
          <div className={styles.nextLink}>
            <NextLink hasNextPage={hasNextPage} pageNumber={pageNumber} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default CareersTemplate

export const query = graphql`
  query GET_CAREERS_AND_GATSBY_IMAGES($ids: [ID]) {
    wpcontent {
      careers(where: { in: $ids }) {
        nodes {
          ...CareerEntryFragment
        }
      }
    }

    bannerBackgroundImage: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }

    banner: file(relativePath: { eq: "careers/careers.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
