import React from "react"
import { Link, graphql } from "gatsby"

// Custom styles & Bootstrap
import Card from "react-bootstrap/Card"
import styles from "./CareerEntry.module.scss"

const CareerEntry = ({ career }) => {
  const d = new Date(career.date)
  const mm = d.toLocaleString("default", { month: "short" })
  const dd = d.getDate()
  const yyyy = d.getFullYear()
  return (
    <Card className={styles.card} style={{ marginBottom: "1.5rem" }}>
      <Card.Body>
        <h4
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: career.title }}
        />
        <p>{`Salary: ${career.acfCareerFields.salary}`}</p>
        <p>{`Average Processing Time: ${career.acfCareerFields.averageProcessingTime}`}</p>
        <p>{`Posted on: ${mm} ${dd}, ${yyyy}`}</p>
      </Card.Body>
      <Card.Footer>
        <Link className={styles.link} to={career.uri}>
          View Details
        </Link>
        {` | `}
        <a
          className={styles.link}
          href={`mailto:nice.dawili@synergyts.co.uk?subject=Application for ${career.title}`}
        >
          Apply Now
        </a>
      </Card.Footer>
    </Card>
  )
}

export default CareerEntry

export const query = graphql`
  fragment CareerEntryFragment on WPGraphQL_Career {
    id
    uri
    title(format: RENDERED)
    date
    acfCareerFields {
      averageProcessingTime
      salary
      workingHours
    }
  }
`
